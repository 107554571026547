import React from 'react';
import Event from '../components/Events';
import TrustedCompanies  from '../components/TrustedCompanies';
const Events = () => {
  return (
    <div>
      <Event />
      <TrustedCompanies />
    </div>
  );
};

export default Events;
