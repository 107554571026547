import React from 'react';
import '../styles/learn.css';

const Learn = () => {
  return (
    <div className="learn-container">
      <div className="learn-content">
        <h1 className="learn-heading">Coming Soon</h1>
        <p className="learn-paragraph">
          The Learn page is currently being developed. We are working hard to bring you the best courses.
          Once we finalize everything, this page will be updated with the latest information.
        </p>
      </div>
    </div>
  );
};

export default Learn;
